<template>
  <div>
    <div
      id="offcanvasChapters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="settings" /></span>
          Send mail template
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>

      <div class="offcanvas-body">
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Mail template</label>
          <v-select
            v-model="mail.mail_template_id"
            label="title"
            :clearable="false"
            :options="data"
            :get-option-key="option => option.id"
            :reduce="e => e.id"
          />
        </div>
        <hr>
        <h5><strong>Users</strong></h5>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >User roles</label>
          <v-select
            v-model="mail.role_id"
            label="name"
            :clearable="false"
            :options="roles"
            :get-option-key="option => option.id"
            :reduce="e => e.id"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Active</label>
          <v-select
            v-model="mail.active"
            label="name"
            :clearable="false"
            :options="[
              {name: 'All', value: null},
              {name: 'Yes', value: true},
              {name: 'No', value: false},
            ]"
            :get-option-key="option => option.name"
            :reduce="e => e.value"
          />
        </div>
        <h5><strong>Memoir</strong></h5>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Memoir year</label>
          <v-select
            v-model="mail.memoir"
            label="name"
            :options="[
              { name: '2028', value: 2028 },
              { name: '2027', value: 2027 },
              { name: '2026', value: 2026 },
              { name: '2025', value: 2025 },
              { name: '2024', value: 2024 },
              { name: '2023', value: 2023 },
            ]"
            :get-option-key="option => option.name"
            :reduce="e => e.value"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Profile status</label>
          <v-select
            v-model="mail.memoir_profile_status"
            label="name"
            :options="memoirStatuses"
            :get-option-key="option => option.id"
            :reduce="e => e.id"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Highlights status</label>
          <v-select
            v-model="mail.memoir_highlights_status"
            label="name"
            :options="memoirStatuses"
            :get-option-key="option => option.id"
            :reduce="e => e.id"
          />
        </div>
      </div>

      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-dark mb-1 d-grid w-100"
          @click="send"
        >
          Send
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    tableId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      mail: {
        active: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/sendMailTemplate',
      data: 'mailTemplates/items',
      roles: 'roles/allRoles',
      memoirStatuses: 'status/statuses',
    }),
  },
  async mounted() {
    await this.$store.dispatch('roles/fetchAll')
    // await this.$store.dispatch('mailTemplates/fetch')
    await this.$store.dispatch('status/filterStatus', 'Memoirs profiles')
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleSendMailTemplate', false)
    },
    async send() {
      if (this.mail.mail_template_id && this.mail.role_id) {
        Vue.swal({
          title: 'Do you want to send the emails?',
          html: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.$store.dispatch('mailTemplates/send', this.mail)
            Vue.swal('', 'Mails send successfully', 'success')
          }
        })
      } else {
        Vue.swal('', 'Please, fill up the mail template and role fields', 'warning')
      }
    },
  },
}
</script>
